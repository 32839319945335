import React from "react"
import styled from 'styled-components'
import { LoadingSpinner} from './LoadingSpinner'


const LoadingOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff94;
  top: 0;
  left: 0;
  z-index:9999;
`

export const SpinnerOverlay = ({ show }) => {
  return (
    <>
      {show && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </>
  )
}

