import React from "react";
import styled, { keyframes } from 'styled-components';
import errorIcon from "../../images/register/circle-error.svg";
import warningIcon from "../../images/register/circle-warning.svg";
import infoIcon from "../../images/register/circle-info.svg";
import successIcon from "../../images/register/circle-success.svg";


const bounce = keyframes`
  0% {
    transform: scale(0.1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
`;

const MessageContainer = styled.div`
  display:flex;
  ${props => (props.type === "success") ? 'background-color:#F2FCF4;border-left:5px solid #a9fa92' : ''};
  ${props => (props.type === "info") ? 'background-color:#F0F6FE;border-left:5px solid #a0d7eb' : ''};
  ${props => (props.type === "warning") ? 'background-color: #FFFBF0;border-left:5px solid #f3ef98' : ''};
  ${props => (props.type === "error") ? 'background-color:#FFF4F4;border-left:5px solid #FF5158' : ''};
  padding:00px 10px;
  margin: 20px 0px;
  max-width:100%;
  transform: scale(1);
  animation: ${bounce} 0.2s ease-in-out 1;
  width: 100%;
`

const Icon = styled.img`
  max-width: 20px;
  @media (min-width: 800px) {
    max-width: 30px;
  }
`

const MessageWrapper = styled.div`
  padding:20px 0px 20px 20px ;
`

const Type = styled.p`
  text-transform: capitalize;
  font-weight:600;
  margin:0px;
  @media (max-width: 374px) {
    font-size:0.6rem;
  }
`

const Message = styled.p`
  margin:0px;
  @media (max-width: 374px) {
    font-size:0.6rem;
  }
`
 
 const FlashMessage = ({message}) => {

  return(
    <MessageContainer type={message.type}>   
          {message.type === "success" && <Icon src={successIcon} /> }
          {message.type === "info" && <Icon src={infoIcon} /> }
          {message.type === "warning" && <Icon src={warningIcon} /> }
          {message.type === "error" && <Icon src={errorIcon} /> }
        <MessageWrapper>
          <Type>{message.type}</Type>
          <Message>{message.message}</Message>
        </MessageWrapper>
    </MessageContainer>
  )
}
export default FlashMessage

