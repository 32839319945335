import { useEffect, useState } from "react"
import getFirebaseInstance from "../components/register/firebase"
const app = import('firebase/compat/app');
const auth = import('firebase/compat/auth');
const database = import('firebase/compat/firestore');
const functions = import('firebase/compat/functions');
const storage = import('firebase/compat/storage');
const loadFirebaseDependencies = Promise.all([app, auth, database, functions, storage]).then(values => {
  return values[0];
});


function useAuth() {
  const [user, setUser] = useState(null)
  const [firebase, setFirebase] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      let unsubscribe
      let publicProfileUnsubscribe;
      loadFirebaseDependencies.then(async app => {
        const firebaseInstance = getFirebaseInstance(app)
        setFirebase(firebaseInstance)
        unsubscribe = await firebaseInstance.auth.onIdTokenChanged(userResult => {
          if (userResult) {
            //getUserProfile is defined in ./firebase
            publicProfileUnsubscribe = firebaseInstance.getUserProfile({
              userId: userResult.uid,
              onSnapshot: r => {
                firebaseInstance.auth.currentUser.getIdTokenResult(true).then(token => {
                  setUser({...userResult._delegate});
                  setLoading(false)
                })
              }
            }) 
           
          }else{
            if(publicProfileUnsubscribe){
                publicProfileUnsubscribe();
            }
            setLoading(false)
            setUser(null);
          } 
        })
      })
 
      return () => {
        if (unsubscribe) {
            unsubscribe()
        }
      }
  }, [])
  return { user, firebase, loading }
}

export default useAuth