import styled from 'styled-components';

const Text = styled.p`
  text-align:${props => props.center ? 'center;' : ''};
  font-family: 'Poppins','Arial';
  line-height: 1.6;
  margin-top: ${props => props.mt ? props.mt : ''};
  margin-bottom: ${props => props.mb ? props.mb : ''};
  margin-right: ${props => props.mr ? props.mr : ''};
  font-style: ${props => props.italic ? "italic" : 'normal'};
  font-weight: ${props => props.weight ? props.weight : 'normal'};
`

export default Text