import styled from 'styled-components';
const Input = styled.input`
  width:95%;
  min-width:280px;
  padding:15px 10px;
  font-size:1rem;
  margin:8px auto;
  border-radius:4px;
  border:none;
  box-shadow:none;
  &:focus, &:active{
    border:1px solid white;
    font-family:'poppins';
    font-size:1rem;
  }
  &::placeholder{
    color:#806767;
    font-size:0.8rem;
    font-family:'poppins';
  }
  background:#f19d9a42;
  ${props => props.shadow ? 'box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2)' :''};
  ${props => props.insetShadow ? 'box-shadow:inset 0 2px 3px hsla(0,0%,0%,0.1)' :''};
`

export default Input
