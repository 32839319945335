
import app from "firebase/compat/app"

const firebaseConfig = {
  apiKey: "AIzaSyAGIEv8HA89JjfJo8nHiAiP2jPGc0AbESU",
  authDomain: "audiodesires-aab7f.firebaseapp.com",
  databaseURL: "https://audiodesires-aab7f.firebaseio.com",
  projectId: "audiodesires-aab7f",
  storageBucket: "audiodesires-aab7f.appspot.com",
  messagingSenderId: "569978534983",
  appId: "1:569978534983:web:917dc4cfb3805f6b6a0562",
  measurementId: "G-2SMG1XGCTP"
};


class Firebase {
  constructor() {
    if(!firebaseInstance) {
      app.initializeApp(firebaseConfig);
      this.auth = app.auth();
      this.db = app.firestore();
      this.functions = app.functions();
      this.storage = app.storage();
    }
  }

  async trackRegister(method, language){
    console.log("trackRegister")
    if(typeof window !== "undefined" && window.dataLayer) {
      window.dataLayer.push({
        event: "sign_up",
      });
    }
  }


  async fetchAudio(audioRef) {
    const storageRef = await this.storage.ref().child(audioRef);
    await storageRef.getDownloadURL().then(url => {
      return url
    }).catch(function(error) {
      //console.log(error); 
    })
  }


  getUserProfile({userId, onSnapshot}){
    return this.db.collection('profiles').doc(userId).onSnapshot(onSnapshot)
   
  } 

  async createUserProfile(userData){
    try {
      const {lang, uid, UTMParameter, method, email } = userData
      await this.db.collection('profiles').doc(uid)
        .set({
          email,
          registerLanguage: lang,
          dateCreated: new Date(),
          favorites: [],
          UTMParameter
        }, {merge: true})
        this.trackRegister(method,lang)
    } catch (err) {
      console.log(err)
    }
  }

  async socialLogin(socialprovider,type,lang,UTMs){
    try{
      const defaultParameter  = {
        utm_campaign:"",
        utm_medium:"",
        utm_source:""
      }
      const constructedParameter = {...defaultParameter,...UTMs}
      let provider;

      if(socialprovider === "Google"){
        provider = new app.auth.GoogleAuthProvider()
      } else if (socialprovider === "Facebook"){
        provider = new app.auth.FacebookAuthProvider();
      }

      const result = await this.auth.signInWithPopup(provider)
      const userExists = await this.db.collection('profiles').doc(result.user.uid).get()

      if(userExists.exists){
        
      } else {
        // console.log("social:REGISTER")
        // set proper language for email templates 
        this.auth ? this.auth.languageCode = lang : this.auth.languageCode = 'en'

        await this.createUserProfile({
          lang,
          email:result.user.email,
          uid:result.user.uid,
          UTMParameter:constructedParameter,
          method:provider,
        })
        return
      }
    } catch (err) {
      console.log(err)
    }
  }

  async register({email, password, lang, UTMs}){
    try{
      const defaultParameter  = {
        utm_campaign:"",
        utm_medium:"",
        utm_source:""
      }
      const constructedParameter = {...defaultParameter,...UTMs}

      // set proper language for email templates 
      this.auth ? this.auth.languageCode = lang : this.auth.languageCode = 'en'
      const result = await this.auth.createUserWithEmailAndPassword(email,password)
      
      await this.createUserProfile({
        lang,
        email,
        uid:result.user.uid,
        UTMParameter:constructedParameter,
        method:'Email',
      })
      this.auth.currentUser.sendEmailVerification()
      return 

    } catch (err){
      console.log(err)
      throw(err)
    }
  }

}

let firebaseInstance;

function getFirebaseInstance() {
  if(!firebaseInstance && app){
    firebaseInstance = new Firebase(app);
    return firebaseInstance;
  }else if(firebaseInstance){
    return firebaseInstance
  }else{
    return null;
  }
}

export default getFirebaseInstance;
