import React, { useState, useEffect, useContext } from "react"
import {SpinnerOverlay} from '../components/register/SpinnerOverlay';
import {Button}from '../components/register/Button';
import Text from '../components/register/Text'
import LineText from '../components/register/LineText'
import Input from '../components/register/Input'
import MainHeading from '../components/register/MainHeading'
import StyledLink from '../components/register/StyledLink'
import FlashMessage from '../components/register/FlashMessage'
import UTMContext from '../components/UTMContext'
import styled from 'styled-components'
import google from "../images/register/google-icon-80x80.png"
import useAuth from "../hooks/useAuth";

const TermsCheckbox =styled.input`

`

const TermsCheckboxLabel =styled.label`
  font-family:'Poppins';
  padding-left:20px;
  
  font-size:0.8rem;
  a {
    font-size:0.8rem;
  }

`
const TermsWrapper = styled.div`
  display:Flex;
  align-items:center;
  padding-top:10px;
  width:90%;
  margin:auto;
`

const  SocialLoginButton =  styled(Button)`
  //width:48%;
  width:100%;
  background: #ececec;
  color:${props => props.white ? "white": "#606060"};
  border-width: 2px;
  border-color: #ececec;
  &:hover{
    color:white;
    background:#c7c7c7;
    border-color: #c7c7c7;
  }
`
const SocialLoginWrapper = styled.div`
  display:flex;
  justify-content:space-between;
`

const ButtonIcon = styled.img`
  height:1rem;
`
const ButtonContentWrapper = styled.div`
  display:flex;
  align-items:center;
  justify-content: center;
`

const ButtonText = styled.p`
  margin:0px;
  padding-left:20px;
  @media (max-width: 479px) {
    padding-left:5px;
  }
`

const ContentWrapper = styled.div`
  max-width:450px;
`

const PageWrapper = styled.div`
  display:flex;
  flex-direction: column;
  padding:20px;
  justify-content: center;
  min-height:100vh;
  align-items: center;
  @media (max-width: 980px) {
    height:100%;
  }
`



const Register = (props) => {
  const {firebase, user} = useAuth();
  const {UTMParameter} = useContext(UTMContext);
  const [flashMessage, setFlashMessage] = useState('');
  const [termsChecked, setTermsChecked]= useState(false);
  const [loading, setLoading] = useState(false)
  const lang = "en";

  const [formValues, setFormValues] = useState({
    email:'',
    password:'',
    confirmPassword: '',
    gender: 'Female',
    relationshipStatus:'Single',
    day: '',
    month:'',
    year:''
  })

  useEffect(() => {
    if(window && user){
      setTimeout(() => {
        window.location.href = `https://bloomstories.com/${lang === "en" ? "": `${lang}/`}login?token=${user.accessToken}&redirectSource=audiodesires&utm_source=${UTMParameter.utm_source}&utm_medium=${UTMParameter.utm_medium}&utm_campaign=${UTMParameter.utm_campaign}`;
      }, 2000);
    }
  }, [user])


  function handleSocialLogin(provider){
    if(termsChecked){
      setLoading(true)
      firebase.socialLogin(provider,"register", lang, UTMParameter)
      .catch(()=>  setLoading(false))
      .then(()=>  setLoading(false));
    } else {
      setFlashMessage({message: 'You must agree to our terms to continue"',type:"error"});
    }
  }

  function handleInputChange(e){
    e.persist();
    setFlashMessage(''); // clean error message when inputs change
    setFormValues(currentValues => ( {
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }

  function handleTermsChange(e){
    setTermsChecked(e.target.checked) 
  }

  function handleSubmit(e){
    e.preventDefault();
    if(formValues.password === formValues.confirmPassword){
      setLoading(true)
      firebase.register({
        email: formValues.email,
        password: formValues.password,
        lang:"en",
        UTMs: UTMParameter
      })
      .catch(error => {
        setFlashMessage({"message": error.message,"type":"error"});
      })
    }else if(formValues.password !== formValues.confirmPassword){
      setFlashMessage({"message": 'Password and Confirm Pw must match',"type":"error"});
    };
  }


  return (
  <PageWrapper>
    <SpinnerOverlay show={loading}/>
    <ContentWrapper>
      <form onSubmit={handleSubmit} marginTopBot>
        <MainHeading center>Signup for free!</MainHeading>
        <Text center>And unlock free audio stories to test us out.</Text>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.email} placeholder="email" type="email" required name="email" autocomplete="on"/>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.password} placeholder="password" type="password" required minLength={6} name="password" autocomplete="on"/>
          <Input insetShadow  onChange={handleInputChange} value = {formValues.confirmPassword} placeholder="confirm password" type="password" required minLength={6} name="confirmPassword" autocomplete="on"/>
          <TermsWrapper>
            <TermsCheckbox onChange={handleTermsChange} type="checkbox" required name="terms" value ={formValues.terms}/>
            <TermsCheckboxLabel id="terms" name="terms">I am 18 years or older and agree to your <StyledLink to={"/terms"}>Terms of Service</StyledLink></TermsCheckboxLabel>
          </TermsWrapper>
          {flashMessage && <FlashMessage message={flashMessage}/>}
          <Button type ="submit" block marginTop>Create Account</Button>
          <LineText>or sign up with</LineText>
          <SocialLoginWrapper>
        <SocialLoginButton type="button" inverted border  onClick={() => handleSocialLogin("Google")}>
          <ButtonContentWrapper>
          <ButtonIcon src={google}/>
          <ButtonText>Google</ButtonText>
          </ButtonContentWrapper>
        </SocialLoginButton>
        </SocialLoginWrapper>
      </form>
    </ContentWrapper>
  </PageWrapper>
  )
}
export default Register