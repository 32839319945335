import styled from 'styled-components';

export const Button = styled.button`
  padding: 10px 15px;
  background:${props => props.inverted ? 'white' : "#9D0A41"};
  background:${props => props.color && props.color }; 
  
  color:${props => props.inverted ? "#9D0A41" : 'white'};
  border-radius:25px;
  border-width: ${props => props.inverted ? '2px' : '0px'};
  border-color:${props => props.inverted ? "#9D0A41" : ''};
  border-style:${props => props.border ? 'solid' : 'none'};
  cursor:pointer;
  text-transform: uppercase;
  white-space:nowrap;
  ${props => props.block ? 'display:block; width:100%;' : ''}
  ${props => props.marginTop ? 'margin-top:20px;' : ''}
  &:hover{
    color:white;
    background:${props => props.inverted ? "#9D0A41"  : "#9D0A41"};
    background:${props => props.color && "#6AA5A6" }; 
  }
  font-family: 'poppins';
  font-size:1rem;
  height: 45px;
  ${props => props.shadow ? 'box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2)' :''};
`
